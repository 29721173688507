.tableRow {
  height: 60px;
  vertical-align: bottom;
}

.tableHeadCellGroups {
  width: 10px;
  padding-left: 12px;
}

.tableHeadCellGroupsWithBorder {
  width: 10px;
  padding-left: 12px;
}

th.tablePaddingLeftCell {
  padding-left: 24px;
}

.sticky {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
}
