div.cardHeader {
  padding: 8px 16px;
  background-color: #505195;
}

.list {
  width: 100%;
  height: 200px;
  overflow: auto;
}
