.questionsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.questionContainer {
  margin-bottom: 24px;
}

.labelContainer {
  width: 100%;
  margin-bottom: 12px;
}

.questionTitle {
  white-space: break-spaces;
}

.inputContainer {
  width: 50%;
}

.errorText {
  padding-left: 14px;
}

.datePicker {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .inputContainer {
    width: 100%;
  }
}
