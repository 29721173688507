.inputContainer {
  width: 64px;
}

.tablePaddingLeftCheckbox {
  width: 10px;
  padding-left: 12px;
}

td.tablePaddingLeftCell {
  padding-left: 24px;
}
