.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.backgroundImage {
  display: flex;
  align-items: start;
  justify-content: center;
  width: auto;
  height: 60%;
  background-image: url('src/assets/error-boundaries.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.errorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px;
}

.errorContainer > * {
  padding: 15px 0;
}

.textContainer {
  color: #373867;
}

.button {
  display: block;
  width: fit-content;
  padding: 5px;
}
