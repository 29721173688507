.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.tabs {
  border-color: 'divider';
  border-bottom: 1px;
}

.tab {
  width: 300px;
}
