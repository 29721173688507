.tablePaddingLeftCheckbox {
  width: 10px;
  padding-left: 12px;
}

.tableHeadCellGroups {
  width: 10px;
  padding-left: 12px;
  border-right: 2px solid #ffffff;
  border-left: 2px solid #ffffff;
}

th.tablePaddingLeftCell {
  padding-left: 24px;
}

.stickyCheckbox {
  position: sticky;
  left: 0;
  z-index: 100;
  width: 10px;
  padding-left: 12px;
}

.stickyName {
  position: sticky;
  top: 120px;
  left: 74px;
  z-index: 100;
  min-width: 130px;
  max-width: 130px;
}

.stickyLastName {
  position: sticky;
  top: 120px;
  left: 236px;
  z-index: 100;
  min-width: 130px;
  max-width: 130px;
  border-right: 1px solid #ffffff;
}
