.cardContainerAdmin,
.cardContainerStudent,
.cardContainerTutor {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 225px;
  height: 230px;
  padding: 8px 0;
  margin: 27px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 10px;
}

.cardContainerAdmin {
  background-image: url('../../../../assets/admin.png');
}

.cardContainerStudent {
  background-image: url('../../../../assets/student.png');
}

.cardContainerTutor {
  background-image: url('../../../../assets/tutor-auxiliar.png');
}

.cardBox {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  justify-content: center;
  height: 35px;
  padding: 0 15px;
  text-align: center;
  border-radius: 0 10px 10px 0;
  box-shadow: 0 4px 4px #00000040;
}

.cardBoxTitle {
  display: flex;
  flex: 1;
  align-items: flex-end;
  justify-content: center;
  padding: 0 10px;
}

.cardTextTitle {
  align-items: center;
  padding: 10px 5px 0;
  margin-bottom: 7px;
  overflow-wrap: break-word;
}

.cardBoxSubtitle {
  display: flex;
  flex: 1;
  align-items: stretch;
  justify-content: space-around;
  margin-top: 14px;
}

.btnContainer {
  display: flex;
  flex: 1;
  align-items: center;
}

.linkButton {
  text-decoration: none;
}

.buttonCard {
  height: 30px;
  padding: 5px 10px;
  text-decoration: none;
}

@media only screen and (max-width: 1050px) {
  .cardContainerAdmin,
  .cardContainerStudent,
  .cardContainerTutor {
    margin: 18px;
  }
}

@media only screen and (max-width: 640px) {
  .cardContainerAdmin,
  .cardContainerStudent,
  .cardContainerTutor {
    margin: 12px;
  }
}
