.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.listContainer {
  flex-grow: 1;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 115px;
  margin: 0 16px;
}

button.arrowButton {
  margin: 4px 0;
}
