.buttonsContainer {
  display: flex;
  justify-content: flex-end;
}

.inputContainer {
  width: 64px;
}

.hidden {
  display: none;
}

.tablePaddingLeftCheckbox {
  width: 10px;
  padding-left: 12px;
}

td.tablePaddingLeftCell {
  padding-left: 24px;
}

.mousePointer:hover {
  cursor: pointer;
}

.stickyCheckbox {
  position: sticky;
  left: 0;
  width: 10px;
  padding-left: 12px;
  background-color: #ffffff;
}

.stickyName {
  position: sticky;
  left: 74px;
  min-width: 130px;
  max-width: 130px;
  overflow: hidden;
  background-color: #ffffff;
}

.stickyLastName {
  position: sticky;
  left: 236px;
  min-width: 130px;
  max-width: 130px;
  overflow: hidden;
  background-color: #ffffff;
}
