.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 40px 0 0;
}

.stepperContainer {
  align-self: flex-end;
  margin-right: 40px;
  margin-bottom: 30px;
}

.btnContainer {
  display: flex;
  justify-content: flex-end;
  padding-top: 35px;
}

.btnContainer button {
  margin-left: 20px;
}
