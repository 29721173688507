.optionContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.startIconContainer {
  margin-right: 8px;
}
