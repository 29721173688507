.container {
  display: flex;
  align-items: center;
  max-width: min-content;
  text-decoration: none;
}

.container h2 {
  margin-left: 10px;
}
